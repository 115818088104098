export default [
  {
    title: 'Tableau de bord',
    route: 'espace-super-admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des Mairies',
    icon: 'ListIcon',
  },
  {
    title: 'Liste des mairies',
    route: 'espace-super-admin.list-mairies',
    icon: 'BriefcaseIcon',
  },
  {
    header: 'Gestion des Utilisateurs',
    icon: 'ListIcon',
  },
  {
    title: 'Liste des utilisateurs',
    route: 'espace-super-admin.list-utilisateurs',
    icon: 'UsersIcon',
  },

]
